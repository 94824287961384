<template>
    <div class="auth-container">
        <div class="auth-text">
            <div class="title">
                Цифровые
                <span>
                    <AlfaLogoIcon />
                    генты:</span
                >
                Секретная служба
            </div>
            <div class="text">Специальная программа подготовки агентов цифрового мышления Альфа-Банка</div>
        </div>
        <AlCard class="register-form" key="reg">
            <div class="back-arrow" @click="switchForm">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        cx="24"
                        cy="24"
                        r="23"
                        fill="#1F1F1F"
                        fill-opacity="0.7"
                        stroke="#DC4433"
                        stroke-width="2"
                    />
                    <path
                        d="M34.56 24.6797C35.1123 24.6797 35.56 24.232 35.56 23.6797C35.56 23.1274 35.1123 22.6797 34.56 22.6797L34.56 24.6797ZM12.0929 22.9726C11.7024 23.3631 11.7024 23.9963 12.0929 24.3868L18.4569 30.7508C18.8474 31.1413 19.4805 31.1413 19.8711 30.7508C20.2616 30.3602 20.2616 29.7271 19.8711 29.3365L14.2142 23.6797L19.8711 18.0228C20.2616 17.6323 20.2616 16.9991 19.8711 16.6086C19.4805 16.2181 18.8474 16.2181 18.4569 16.6086L12.0929 22.9726ZM34.56 22.6797L12.8 22.6797L12.8 24.6797L34.56 24.6797L34.56 22.6797Z"
                        fill="#DC4433"
                    />
                </svg>
            </div>
            <div class="form-title">Идентификация</div>
            <b-form @submit="onSubmit">
                <b-form-group id="register">
                    <b-form-input
                        name="lastName"
                        placeholder="Фамилия"
                        @change.native="onChange"
                        :state="$v.formRegister.lastName.$error ? false : null"
                    ></b-form-input>
                    <b-form-input
                        name="firstName"
                        placeholder="Имя"
                        @change.native="onChange"
                        :state="$v.formRegister.firstName.$error ? false : null"
                    ></b-form-input>
                    <b-form-input
                        name="middleName"
                        placeholder="Отчество"
                        @change.native="onChange"
                        :state="$v.formRegister.middleName.$error ? false : null"
                    ></b-form-input>
                    <b-form-input
                        name="email"
                        placeholder="E-mail"
                        @change.native="onChange"
                        :state="$v.formRegister.email.$error ? false : null"
                    ></b-form-input>
                    <al-password-input
                        name="password"
                        placeholder="Пароль"
                        type="password"
                        @change.native="onChange"
                        :state="$v.formRegister.password.$error ? false : null"
                    />
                    <al-password-input
                        name="passwordRepeat"
                        placeholder="Подтвердите пароль"
                        type="password"
                        @change.native="onChange"
                        :state="$v.formRegister.passwordRepeat.$error ? false : null"
                    />
                    <div class="error-title" v-if="getFirstError || serverError">{{ errorText || serverError }}</div>
                    <b-button variant="primary" type="submit">Подтвердить</b-button>
                </b-form-group>
            </b-form>
        </AlCard>
    </div>
</template>
<script>
import AlCard from '@/components/AlCard.vue';
import AlPasswordInput from '@/components/AlPasswordInput';
import AlfaLogoIcon from '@/assets/images/icons/AlfaLogoIcon.svg';
import { required, sameAs, email, helpers } from 'vuelidate/lib/validators';
const passwordMatch = helpers.regex('passwordMatch', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);

export default {
    name: 'Register',
    components: { AlCard, AlPasswordInput, AlfaLogoIcon },
    data: () => ({
        formRegister: {
            firstName: '',
            lastName: '',
            middleName: '',
            email: '',
            password: '',
            passwordRepeat: '',
        },
        serverError: '',
    }),
    validations: {
        formRegister: {
            lastName: {
                required,
            },
            firstName: {
                required,
            },
            middleName: {
                required,
            },
            email: {
                required,
                email,
            },
            password: {
                required,
                passwordMatch,
            },
            passwordRepeat: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
    },
    computed: {
        getFirstError() {
            if (this.$v.$anyError) {
                return Object.keys(this.$v.formRegister).find(x => this.$v.formRegister[x].$error === true);
            }
        },
        errorText() {
            if (this.getFirstError === 'firstName') return 'Введите имя';
            if (this.getFirstError === 'lastName') return 'Введите фамилию';
            if (this.getFirstError === 'middleName') return 'Введите отчество';
            if (this.getFirstError === 'email') return 'Введите корректный Email';
            if (this.getFirstError === 'password')
                return 'Пароль должен содержать не менее 8-ми символов, минимум 1 заглавную, строчную латинскую букву, цифру';
            if (this.getFirstError === 'passwordRepeat') return 'Пароли не совпают';
        },
    },
    methods: {
        onChange(e) {
            this.formRegister[e.target.name] = e.target.value;
        },
        async onSubmit(event) {
            event.preventDefault();

            this.$v.formRegister.$touch();
            if (this.$v.formRegister.$anyError) {
                return;
            }
            this.serverError = '';
            const registerInput = new FormData();
            registerInput.append('email', this.formRegister.email.trim().toLowerCase());
            registerInput.append('password', this.formRegister.password);
            registerInput.append('first_name', this.formRegister.firstName.trim());
            registerInput.append('last_name', this.formRegister.lastName.trim());
            registerInput.append('middle_name', this.formRegister.middleName.trim());
            await this.$store
                .dispatch('auth/registration', registerInput)
                .then(async () => {
                    const loginInput = new FormData();
                    loginInput.append('email', this.formRegister.email.trim().toLowerCase());
                    loginInput.append('password', this.formRegister.password);
                    await this.$store
                        .dispatch('auth/authorization', loginInput)
                        .then(() => {
                            this.$router.push('/?showLegend=true');
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    if (error?.response.status === 400) {
                        this.serverError = 'Пользователь с таким Email уже зарегистрирован';
                    } else {
                        this.serverError = 'Что-то пошло не так';
                    }
                });
        },
        switchForm() {
            this.$router.push('/auth');
            this.$v.$reset();
            this.formRegister = {
                firstName: '',
                lastName: '',
                middleName: '',
                email: '',
                password: '',
                passwordRepeat: '',
            };
        },
    },
};
</script>
<style lang="scss" scoped></style>
